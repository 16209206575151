import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { onAuthStateChanged, signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { v4 as uuidv4 } from 'uuid';
import About from './About';
import Header from './components/Header';
import ChatBox from './components/ChatBox';
import useChat from './hooks/useChat';
import characters from './characters';
import { auth, analytics } from './firebase';
import { logEvent } from 'firebase/analytics';
import useBitcoinData from './hooks/useBitcoinData';
import './App.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function App() {
  const [user, setUser] = useState(null);
  const { blockHeight, bitcoinPrice, feeEstimates } = useBitcoinData();
  const {
    statement,
    setStatement,
    personality,
    language,
    conversation,
    loading,
    typing,
    chatBoxRef,
    handleKeyPress,
    generateArgument,
    selectCharacter,
    toggleLanguage,
  } = useChat();

  useEffect(() => {
    const storedEmail = localStorage.getItem('userEmail');
    const storedPassword = localStorage.getItem('userPassword');

    const signInOrCreateUser = async (email, password) => {
      try {
        await signInWithEmailAndPassword(auth, email, password);
      } catch (error) {
        if (error.code === 'auth/user-not-found') {
          await createUserWithEmailAndPassword(auth, email, password);
        } else {
          console.error('Error signing in or creating user:', error);
        }
      }
    };

    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (!currentUser) {
        if (storedEmail && storedPassword) {
          await signInOrCreateUser(storedEmail, storedPassword);
        } else {
          const randomEmail = `${uuidv4()}@example.com`;
          const randomPassword = uuidv4();

          await createUserWithEmailAndPassword(auth, randomEmail, randomPassword);

          localStorage.setItem('userEmail', randomEmail);
          localStorage.setItem('userPassword', randomPassword);
        }
      }
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);

  const handleKeyPressWithAnalytics = (event) => {
    if (event.key === 'Enter' && !loading) {
      logEvent(analytics, 'user_input', { input: statement });
      handleKeyPress(event);
    }
  };

  const generateArgumentWithAnalytics = async () => {
    logEvent(analytics, 'backend_request', { input: statement });
    await generateArgument();
  };

  return (
    <Router>
      <div className="flex flex-col h-screen">
        <Header
          character={personality}
          language={language}
          toggleLanguage={toggleLanguage}
          selectCharacter={selectCharacter}
          characters={characters}
          user={user}
        />
        <Routes>
          <Route path="/" element={
            <main className="flex flex-col flex-grow container mx-auto p-4">
              <div className="block-height-price-background hidden">
                {blockHeight} | {bitcoinPrice} | {feeEstimates} sat/vB
              </div>
              <ChatBox
                chatBoxRef={chatBoxRef}
                conversation={conversation}
                typing={typing}
                language={language}
                personality={personality}
              />
              <div className="input-box-wrapper fixed bottom-0 left-0 right-0 p-4 bg-gray-800">
                <div className="container mx-auto">
                  <div className="input-box flex items-center bg-white rounded-full shadow-sm p-2">
                    <input
                      type="text"
                      placeholder={language === 'de' ? "Deine Nachricht..." : "Your message..."}
                      value={statement}
                      onChange={(e) => setStatement(e.target.value)}
                      onKeyPress={handleKeyPressWithAnalytics}
                      className="flex-grow p-3 rounded-full focus:outline-none transition bg-white border-none text-sm md:text-base"
                    />
                    <button
                      onClick={generateArgumentWithAnalytics}
                      className="submit-button p-3 bg-gray-800 text-white hover:bg-gray-900 transition disabled:bg-gray-400 rounded-full"
                      disabled={loading}
                    >
                      {loading ? <span className="spinner-border spinner-border-sm"></span> : <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 12h14M12 5l7 7-7 7" /></svg>}
                    </button>
                  </div>
                  <div className="text-center text-white text-xxs mt-2">
                    {language === 'de' ? 'Bitcoin Buddy kann Fehler machen. Überprüfe wichtige Informationen.' : 'Bitcoin Buddy can make mistakes. Check important information.'}
                  </div>
                </div>
              </div>
            </main>
          } />
          <Route path="/about" element={<About language={language} />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
