import React, { useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';

/**
 * ChatBox component
 * 
 * This component renders the chat box containing the conversation messages.
 *
 * @param {Object} chatBoxRef - Ref object to manage scrolling behavior.
 * @param {Array} conversation - Array of conversation messages.
 * @param {boolean} typing - Indicates if the assistant is typing.
 * @param {string} language - Current language setting.
 * @param {Object} personality - Current personality object.
 * 
 * @returns {JSX.Element} The ChatBox component.
 */
const ChatBox = ({ chatBoxRef, conversation, typing, language, personality }) => {
  const endOfMessagesRef = useRef(null);

  useEffect(() => {
    // Scroll to the bottom only if there are more than one messages
    if (conversation.length > 1) {
      endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [conversation, typing]);

  return (
    <div ref={chatBoxRef} className="chat-box">
      {conversation.map((msg, index) => (
        <div 
          key={index} 
          className={`message-bubble mb-3 ${msg.role === 'user' ? 'user-message' : 'assistant-message'} ${msg.role !== 'user' ? 'has-profile-pic' : ''}`}
        >
          <div className="relative flex items-start">
            {msg.role !== 'user' && (
              <img
                src={personality.image}
                alt={personality.forename}
                className="profile-pic"
              />
            )}
            <div className="message-content">
              <ReactMarkdown>{msg.content}</ReactMarkdown>
            </div>
          </div>
        </div>
      ))}
      {typing && (
        <div className="message-bubble assistant-message mb-3">
          {language === 'de' ? 'schreibt...' : 'Typing...'}
        </div>
      )}
      <div ref={endOfMessagesRef}></div>
    </div>
  );
};

export default ChatBox;
