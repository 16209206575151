import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import characters from '../characters';
import { analytics, auth } from '../firebase';
import { logEvent } from 'firebase/analytics';

const FASTAPI_URL = process.env.REACT_APP_FASTAPI_URL;
console.log('FASTAPI_URL:', FASTAPI_URL); // Debugging line

const useChat = () => {
  const initialCharacter = characters.find(character => character.name === 'Anna Fischer'); // Updated to Anna
  const [statement, setStatement] = useState('');
  const [personality, setPersonality] = useState(initialCharacter);
  const [language, setLanguage] = useState('en');
  const [conversation, setConversation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [typing, setTyping] = useState(true);
  const chatBoxRef = useRef(null);

  const db = getFirestore(); // Initialize Firestore

  useEffect(() => {
    const welcomeMessage = {
      role: 'assistant',
      content: personality.greetings[language] ? personality.greetings[language] : `Hello, I'm ${personality.forename}. How can I assist you today?`
    };
    setTimeout(() => {
      setTyping(false);
      setConversation([welcomeMessage]);
    }, 1000);
  }, [personality, language]);

  const generateArgument = async () => {
    setLoading(true);
    const userMessage = { role: 'user', content: statement };
    setConversation([...conversation, userMessage]);

    // Log the user statement
    logEvent(analytics, 'user_input', { input: statement });

    // Store the user message in Firestore
    const user = auth.currentUser;
    if (user) {
      await addDoc(collection(db, 'messages'), {
        uid: user.uid,
        role: 'user',
        content: statement,
        timestamp: new Date()
      });
    }

    setStatement('');
    setTyping(true);

    try {
      console.log('Request URL:', `${FASTAPI_URL}/generate_argument`); // Debugging line
      const response = await axios.post(`${FASTAPI_URL}/generate_argument`, {
        statement,
        personality: personality.name,
        language,
        conversation,
      });

      const assistantMessage = { role: 'assistant', content: response.data.argument };
      setConversation((prevConversation) => [...prevConversation, assistantMessage]);

      // Log the API response
      logEvent(analytics, 'api_response', { response: response.data.argument });

      // Store the assistant message in Firestore with the character's forename
      if (user) {
        await addDoc(collection(db, 'messages'), {
          uid: user.uid,
          role: 'assistant',
          content: response.data.argument,
          character: personality.forename, // Add the character's forename
          timestamp: new Date()
        });
      }

    } catch (error) {
      console.error("Error generating argument", error);
    }

    setTyping(false);
    setLoading(false);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !loading) {
      generateArgument();
    }
  };

  const selectCharacter = (character) => {
    setPersonality(character);
    setTyping(true);
    setConversation([]);
    const welcomeMessage = {
      role: 'assistant',
      content: character.greetings[language] ? character.greetings[language] : `Hello, I'm ${character.forename}. How can I assist you today?`
    };
    setTimeout(() => {
      setTyping(false);
      setConversation([welcomeMessage]);
    }, 1000);
  };

  const toggleLanguage = () => {
    setLanguage((prevLanguage) => (prevLanguage === 'en' ? 'de' : 'en'));
  };

  return {
    statement,
    setStatement,
    personality,
    setPersonality,
    language,
    conversation,
    loading,
    typing,
    chatBoxRef,
    handleKeyPress,
    selectCharacter,
    toggleLanguage,
    generateArgument, // Include this line to make sure the function is returned
  };
};

export default useChat;
