import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';
import { FiLogOut } from 'react-icons/fi'; // Import the logout icon

const Header = ({ character, language, toggleLanguage, user }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = () => {
    signOut(auth);
  };

  // Define a variable to conditionally render the logout button
  const showLogoutButton = false; // Change this to true if you want to show the logout button

  return (
    <header className="header">
      <div className="container flex items-center justify-between">
        <div className="relative">
          <img
            src={character.image}
            alt={character.forename}
            className="profile-pic rounded-full"
          />
        </div>
        <div className="flex items-center">
          <div className="text-lg">{character.forename}</div>
        </div>
        <div className="flex items-center space-x-4">
          <button
            onClick={toggleLanguage}
            className="bg-transparent text-white focus:outline-none border-none"
            aria-label={`Switch to ${language === 'de' ? 'English' : 'German'}`}
          >
            <img
              src={language === 'de' ? '/images/german.jpg' : '/images/english.jpg'}
              alt={language === 'de' ? 'Switch to English' : 'Wechseln zu Deutsch'}
              className="w-6 h-6"
            />
          </button>
          <div className="relative">
            <button onClick={toggleMenu} className="text-white focus:outline-none" aria-label="Toggle menu">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
              </svg>
            </button>
            {isMenuOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-gray-800 text-white rounded-md shadow-lg z-10">
                <Link to="/" className="block px-4 py-2 hover:bg-gray-700" onClick={toggleMenu}>Home</Link>
                <Link to="/about" className="block px-4 py-2 hover:bg-gray-700" onClick={toggleMenu}>About</Link>
                {user && showLogoutButton && (
                  <div
                    onClick={handleLogout}
                    className="block px-4 py-2 hover:bg-gray-700 cursor-pointer flex items-center"
                  >
                    <FiLogOut className="mr-2" /> Logout
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
