// src/hooks/useBitcoinData.js
import { useState, useEffect } from 'react';

const useBitcoinData = () => {
  const [blockHeight, setBlockHeight] = useState(null);
  const [bitcoinPrice, setBitcoinPrice] = useState(null);
  const [feeEstimates, setFeeEstimates] = useState(null);

  useEffect(() => {
    const fetchBlockHeight = async () => {
      try {
        const response = await fetch('https://blockstream.info/api/blocks/tip/height');
        const data = await response.text();
        const formattedBlockHeight = `Block ${new Intl.NumberFormat().format(data).replace(/,/g, ' ')}`;
        setBlockHeight(formattedBlockHeight);
      } catch (error) {
        console.error('Error fetching block height:', error);
      }
    };

    const fetchBitcoinPrice = async () => {
      try {
        const response = await fetch('https://api.coindesk.com/v1/bpi/currentprice/BTC.json');
        const data = await response.json();
        const roundedPrice = Math.round(data.bpi.USD.rate_float);
        const formattedBitcoinPrice = `$${new Intl.NumberFormat().format(roundedPrice).replace(/,/g, ' ')}`;
        setBitcoinPrice(formattedBitcoinPrice);
      } catch (error) {
        console.error('Error fetching Bitcoin price:', error);
      }
    };

    const fetchFeeEstimates = async () => {
      try {
        const response = await fetch('https://blockstream.info/api/fee-estimates');
        const data = await response.json();
        const currentNetworkFee = Math.round(data[1]);
        setFeeEstimates(currentNetworkFee);
      } catch (error) {
        console.error('Error fetching fee estimates:', error);
      }
    };

    fetchBlockHeight();
    fetchBitcoinPrice();
    fetchFeeEstimates();

    const intervalId = setInterval(() => {
      fetchBlockHeight();
      fetchBitcoinPrice();
      fetchFeeEstimates();
    }, 600000);

    return () => clearInterval(intervalId);
  }, []);

  return { blockHeight, bitcoinPrice, feeEstimates };
};

export default useBitcoinData;
