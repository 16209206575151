const characters = [
  {
    name: 'Anna Fischer',
    forename: 'Anna',
    image: '/images/anna_fischer.jpg',
    audio: '/sounds/anna_fischer.mp3',
    greetings: {
      en: "Hi there! I'm Anna, it's so nice to meet you! Whether you have questions or concerns about Bitcoin, I'm here to help. 😊 I'd love to get to know you better—what's your name?",
      de: "Hey! Ich bin Anna, freut mich sehr, dich kennenzulernen! Egal ob du Fragen hast oder dir etwas Sorgen wegen Bitcoin machst, ich bin für dich da. 😊 Ich würde dich gerne besser kennenlernen—wie heißt du?"
    }
  }
];

export default characters;
